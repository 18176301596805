import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import logo from './logo.svg';
import './App.css';

function App() {


  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path='/'  element={<Home />} />
        </Routes>
      </div>
    </HashRouter>
    
  );
}

export default App;
